<template>
    <div id="blog">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->

        <!-- Header Start -->
        <SiteHeader></SiteHeader>
        <!-- Header End -->

        <!-- Page Header Start -->
        <div class="page-header bg-section parallaxie">
            <!-- Page Header Box Start -->
            <div class="page-header-box">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Page Header Content Start -->
                            <div class="page-header-content section-title">
                                <h3 class="wow fadeInUp text-white">WELCOME TO INGOMU</h3>
                                <h1 class="wow fadeInUp">{{ this.name }}</h1>
                            </div>
                            <!-- Page Header Content End -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page Header Box End -->
        </div>
        <!-- Page Header End -->

        <!-- Page Service Single Start -->
        <div class="page-service-single pb-0" id="description">
            <div class="container">
                <div class="row">

                    <div class="col-lg-9 wow fadeInUp" data-wow-delay="0.25s">
                        <!-- Service Single Content Start -->
                        <div class="post-item-content bg-section">
                            <div class="post-meta pb35">
                                <h2 class="wow fadeInUp" data-wow-delay="0.8s">Welcome to the Ingomu App</h2>
                                <p class="wow fadeInUp mb-20" data-wow-delay="0.8s">
                                    We're thrilled you've joined us on this journey of growth and transformation. Get ready to unlock unlimited access to expert coaching, live virtual sessions, on-demand resources, and personalized support. Whether you're looking to enhance your well-being, achieve career goals, or build resilience, Ingomu is here to guide you every step of the way. Let’s get started and make positive change happen together!
                                </p>
                                <!-- <p class="wow fadeInUp" data-wow-delay="0.8s">

                                </p> -->
                                <h2 class="wow fadeInUp">Download the Ingomu App</h2>

                                <!-- Hero Button Start -->
                                <div class="hero-btn wow fadeInUp row col-lg-12" data-wow-delay="0.4s">
                                    <div class="col-md-3 col-6 mb-3">
                                        <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank">
                                            <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/app_badge.svg"
                                                alt="" class="h-50">
                                        </a>
                                    </div>
                                    <div class="col-md-3 col-6 mb-3">
                                        <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu"
                                            target="_blank">
                                            <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/google_badge.png"
                                                alt="" class="h-50">
                                        </a>
                                    </div>
                                </div>
                                <!-- Hero Button End -->

                                <p class="wow fadeInUp mb-20" data-wow-delay="0.8s">If you have a personal subscription that you paid for, please go to the Apple App Store or Google Play Store on your device and cancel your subscription at least 24 hours before your renewal date to avoid being charged.</p>
                                
                                    <div class="terms-content__box  mb-20">
                                    <h2>Expiration</h2>
                                    <p class="wow fadeInUp" data-wow-delay="0.8s" v-if="expireTerm == 'annual'">Your corporate subscription
                                        expires on <span class="bold">{{ annualExpireDate() }}</span>.</p>
                                    <p class="wow fadeInUp" data-wow-delay="0.8s" v-if="expireTerm == 'semi'">Your corporate subscription expires
                                        on <span class="bold">{{ semiExpireDate() }}</span>.</p>
                                </div>

                                <h2 class="wow fadeInUp">Support</h2>
                                <p class="wow fadeInUp" data-wow-delay="0.8s">We're here to support you on your coaching journey! <router-link :to="{ name: 'welcome' }">Click here</router-link> to visit our welcome page with helpful videos. If you have questions or need assistance, feel free to email us at <a href="mailto:contact@ingomu.com">contact@ingomu.com</a> or reach out through our <router-link :to="{ name: 'contact' }">website</router-link>. A real person will respond, typically within the same business day (Monday to Friday, 8:00 a.m. to 6:00 p.m. Eastern Time). Please provide as much detail as possible so we can offer the most relevant response.
                                    <br><br>
                                    To ensure you receive our reply, please add ingomu.com to your trusted sender list. If you don’t see our response in your inbox, be sure to check your spam or promotions folder, as email gremlins sometimes direct our messages there.
                                </p>
                            </div>


                        </div>
                        <!-- Service Single Content End -->
                    </div>

                    <div class="col-lg-3">
                        <!-- Service Category List Start -->
                        <div class="service-catagery-list wow fadeInUp">
                            <img :src="corpImage" alt="">
                        </div>
                        <!-- Service Category List End -->
                    </div>

                </div>
            </div>
        </div>
        <!-- Page Service Single End -->

        <!-- Our Blog Section Start -->
        <div class="our-blog" v-if="blogHome.length > 0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <!-- Our Blog Content Start -->
                        <div class="our-blog-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">BLOG</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Updates & <span>Coaching Insights</span>
                                </h2>
                            </div>
                            <!-- Section Title End -->

                        </div>
                        <!-- Our Blog Content End -->
                    </div>

                    <div class="col-lg-8">
                        <!-- Blog Box Start -->
                        <div class="blog-box">
                            <!-- Blog Item Start -->
                            <div class="blog-item wow fadeInUp" v-for="blog in blogHome" :key="blog.slug">
                                <!-- Blog Featured Image Start -->
                                <div class="blog-featured-image">
                                    <router-link :to="{ name: 'blogdetail', params: { slug: blog.slug }, }"
                                        class="image-anime">
                                        <figure>
                                            <img :src="imageLink(blog.xlimage)" alt="">
                                        </figure>
                                    </router-link>
                                </div>
                                <!-- Blog Featured Image End -->

                                <!-- Blog Item Body Start -->
                                <div class="blog-item-body">
                                    <!-- Blog Item Content Start -->
                                    <div class="blog-item-content">
                                        <h2>
                                            <router-link :to="{ name: 'blogdetail', params: { slug: blog.slug } }">
                                                {{ blog.title }}
                                            </router-link>
                                        </h2>
                                        <p>{{ strippedHtml(blog.body) }}...</p>
                                    </div>
                                    <!-- Blog Item Content End -->

                                    <!-- Blog Item Button Start -->
                                    <div class="blog-item-btn">
                                        <router-link :to="{ name: 'blogdetail', params: { slug: blog.slug }, }"
                                            class="btn-default">read more</router-link>
                                    </div>
                                    <!-- Blog Item Button End -->
                                </div>
                                <!-- Blog Item Body End -->
                            </div>
                            <!-- Blog Item End -->
                        </div>
                        <!-- Blog Box End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Our Blog Section End -->

        <!-- Page Book Appointment Start -->
        <div class="page-book-appointment bg-section mb-60">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <!-- Book Appointment Content Start -->
                        <div class="book-appointment-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp" data-wow-delay="0.2s">Weekly coaching tips in your inbox</h3>
                                <h2 class="wow fadeInUp">ACTIONABLE TIPS<br><span>FOR A BETTER LIFE</span></h2>
                            </div>
                            <!-- Section Title Start -->
                        </div>
                        <!-- Book Appointment Content End -->
                    </div>

                    <div class="col-lg-7">
                        <!-- Book Appointment Form Start -->
                        <div class="appointment-form">
                            <p class="wow fadeInUp">By subscribing to our newsletter, you allow us to contact you at the
                                email address provided. You may unsubscribe at any time. Your information will never be
                                shared or sold.</p>

                            <!-- Appointment Form Start -->
                            <form id="newsletterForm" action="#" method="POST" data-toggle="validator"
                                class="wow fadeInUp" data-wow-delay="0.25s">
                                <div class="row">
                                    <div class="form-group col-md-12 mb-4">
                                        <input type="email" name="email" class="form-control" id="email"
                                            placeholder="Enter your email" required>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="col-md-12">
                                        <button type="submit" class="btn-default mb-25">Subscribe</button>
                                        <div id="msgSubmit" class="h3 hidden"></div>
                                    </div>
                                </div>
                            </form>
                            <!-- Appointment Form End -->
                        </div>
                        <!-- Book Appointment Form End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Book Appointment End -->

        <!-- Footer Start -->
        <SiteFooter></SiteFooter>
        <!-- Footer End -->
    </div>
</template>
<script>
import SiteHeader from "@/components/ThankYouHeader";
import SiteFooter from "@/components/InfineFooter";
import $ from "jquery";
import { slicknav } from '../../../mixins/slicknav';
import { parrallaxie } from "../../../mixins/parallaxie";
import { mapActions, mapGetters } from "vuex";
import { reveal } from "../../../mixins/reveal";
import formateDateMixin from "@/mixins/formatDate";

export default {
    name: 'companythankyoupaid',
    metaInfo() {
        return {
            title: "Thank you for joining Ingomu",
        }
    },
    components: {
        SiteHeader,
        SiteFooter
    },
    mixins: [formateDateMixin],
    data() {
        return {
            corpImage: "https://dktoyr513tjgs.cloudfront.net/image/ingomu_logo-2.png",
            description: "",
            name: "",
            expireTerm: "annual"

        }
    },
    mounted() {
        this.expireTerm = this.$route.params.term
        this.$store.dispatch("getCorpDetails", { slug: this.$route.params.slug })
        var $window = $(window);
        var $this = this;

        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);

            /* Animated Wow Js */
            new WOW().init();

            // Parallax
            parrallaxie()

            // Reveal
            reveal()

            /* Slick Menu JS */
            slicknav()
        })
        this.$store.dispatch("getHomeBlogList");

    },
    updated: function() {
        this.$nextTick(function() {
            this.scrollToTop();
        })
    },
    computed: {
        ...mapGetters(["corpDetails", "codeGroupDetails", "blogHome"]),
    },
    watch: {
        corpDetails(newValue) {
            if (newValue.length <= 0) {
                this.$router.push({ path: '/404' })
            } else {
                if (newValue[0].corpimage != "" && newValue[0].corpimage != null) {
                    this.corpImage = newValue[0].corpimage;
                }
                this.name = newValue[0].name
                this.description = newValue[0].description
            }
        }
    },
    methods: {
        ...mapActions(["newsletterConsumer"]),
        submit(email) {
            var _this = this;
            _this
                .newsletterConsumer({
                    email: email
                })
        },
        strippedHtml(htmlText) {
            return htmlText.replace(/<[^>]+>/g, '').split(' ').slice(0, 25).join(" ")
        },
        imageLink(url) {
            // return unescape(url.substring(0, url.indexOf("&token=")))
            return unescape(url.substring(0, url.indexOf("&token=")))
        },
        scrollToTop() {
          window.scrollTo(0, 0);
      }
    }

}
</script>
<style scoped>
.page-header {
    background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu.signup.blue.png);
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-60 {
    margin-bottom: 60px;
}

.page-book-appointment {
    background: var(--white-color);
    padding: 100px 0;
}

.h-50 {
    height: 50px !important;
}

.post-item-content {
    background: var(--white-color);
    padding: 50px;
    margin: 0;
    width: 100%;
}

@media (max-width:480px) {
    .page-header {
        background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu.signup.blue.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .service-catagery-list {
        margin-top: 30px;
    }
}
</style>